<template>
  <authorization-layout> </authorization-layout>
</template>

<script>
import AuthorizationLayout from "@/web/components/shared/layout/AuthorizationLayout";

export default {
  name: "Dashboard",
  components: { AuthorizationLayout },
};
</script>

<style scoped></style>
